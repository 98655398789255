import React from 'react'
import { Header, Product } from '../../components'

export default function index() {
    return (
        <div>
            <Header />
            <Product />
        </div>
    )
}
